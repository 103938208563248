import chainWeb3 from './ChainWeb3.js';
import HaloNFT from './model/HaloNFT.js';
import StakePool from './model/StakePool.js';

var ChainApi = {
  chainWeb3: chainWeb3,
  haloNFT: new HaloNFT(chainWeb3),
  StakePool: new StakePool(chainWeb3),
}

export default ChainApi
