import { createRouter, createWebHistory, createWebHashHistory} from "vue-router";

const home = () => import("../components/Home")
//const home1 = () => import("../components/Home1")
const staking = () => import("../components/Staking")
const gallery = () => import("../components/Gallery")
const metaverse = () => import("../components/Metaverse")
const protocol = () => import("../components/Protocol")
const about = () => import("../components/About")
const spark = () => import("../components/Spark")
const haloWorld = () => import("../components/HaloWorld")
const haloStake = () => import("../components/HaloStake")
const product = () => import("../components/Product")
const upgrade = () => import("../components/Upgrade")
const mint = () => import("../components/OcMint")
//const vote = () => import("../components/Vote")

const routes = [
  /*
  {
    path: '/test-a-index',
    name: "index1",
    component: home1,
    meta: {
      title: 'HOME',
      whiteTextHeader: true,
      bgFullWrap: true,
      bgClass: 'bg-home'
    }
  },
  */
  {
    path: '/',
    name: "index",
    component: home,
    meta: {
      title: 'HOME',
      whiteTextHeader: true,
      bgFullWrap: false,
      bgClass: 'bg-home'
    }
  },
  {
    path: "/staking",
    name: "staking",
    component: staking,
    meta: {
      title: 'STAKING',
      bgImg: 4,
      bgFullWrap: false,
      bgClass: 'bg-position-bottom-center'
    }
  },
  {
    path: "/gallery",
    name: "gallery",
    component: gallery,
    meta: {
      title: 'GALLERY'
    }
  },
  {
    path: "/haloworld",
    name: "haloWorld",
    component: haloWorld,
    meta: {
      title: 'HALOWORLD'
    }
  },
  {
    path: "/metaverse",
    name: "metaverse",
    component: metaverse,
    meta: {
      title: 'HALO METAVERSE',
    }
  },
  {
    path: "/protocol",
    name: "protocol",
    component: protocol,
    meta: {
      title: 'protocol',
      bgImg: 2,
      bgFullWrap: false,
    }
  },
  {
    path: "/about",
    name: "about",
    component: about,
    meta: {
      title: 'ABOUT US',
      bgClass: 'bg-secondary'
    }
  },
  {
    path: "/spark",
    name: "spark",
    component: spark,
    meta: {
      title: 'SPARK',
      bgImg: 3,
      bgFullWrap: false,
    }
  },
  {
    path: "/halo_earn",
    name: "halo stake",
    component: haloStake,
    meta: {
      title: 'HALO Earn',
    }
  },
  {
    path: "/haloworld_entry",
    name: "haloworld_entry",
    component: product,
    meta: {
      title: 'HALOWORLD',
    }
  },
  {
    path: "/upgrade",
    name: "upgrade",
    component: upgrade,
    meta: {
      title: 'Upgrade',
    }
  },
  {
    path: "/mint",
    name: "mint",
    component: mint,
    meta: {
      title: 'Mint',
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHashHistory(),
  linkActiveClass:'active-link',
  routes: routes
})

export default router