const APP_URL = "https://ipfs-v2.halonft.art";

const APP_IMG_URL = {
    1 : "https://ipfs-v2.halonft.art/eth/image",
    5 : "https://ipfs-v2.halonft.art/eth/image",
    56 : "https://ipfs-v2.halonft.art/bsc/image",
    97 : "https://ipfs-v2.halonft.art/bsc/image",
};

const APP_DETAIL_IMG_URL = {
    1 : "https://ipfs-v2.halonft.art/web/eth",
    5 : "https://ipfs-v2.halonft.art/web/eth",
    56 : "https://ipfs-v2.halonft.art/web/bsc",
    97 : "https://ipfs-v2.halonft.art/web/bsc",
};

const APP_IMG_TYPE = {
    1 : "jpg",
    5 : "jpg",
    56 : "png",
    97 : "png",
};

const APP_TOKEN_URL = {
    1 : "https://ipfs-v2.halonft.art/eth/token",
    5 : "https://ipfs-v2.halonft.art/eth/token",
    56 : "https://ipfs-v2.halonft.art/bsc/token",
    97 : "https://ipfs-v2.halonft.art/bsc/token",
};

export default { APP_URL, APP_IMG_URL, APP_DETAIL_IMG_URL, APP_TOKEN_URL, APP_IMG_TYPE };