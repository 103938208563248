import HaloNFTABI from '../abi/HaloNFT.json';
import BigNumber from "bignumber.js";
import BaseByName from './BaseByName';

export default class HaloNFT extends BaseByName {
    constructor(provider) {
        super(provider, HaloNFTABI, 'HaloNFT');
    }

    async getSign(addr) {
        if(!addr) addr = this.provider.account;
        addr = addr.toLowerCase();
        let res = await fetch(`/whitelist.${this.provider.chainId}.json?${new Date().getTime()}`).then(res => res.json());
        let whiteList = res;
        console.log(res, whiteList);
        if(whiteList.hasOwnProperty(addr)) return whiteList[addr];
        return '';
    }

    /**
     * @returns 
     */
    async getPrivatePrice() {
        let res = await this.parameters();
        return res.privatePrice;
    }

    /**
     * @returns 
     */
    async getPublicPrice() {
        let res = await this.parameters();
        return res.publicPrice;
    }

    /**
     * @returns 
     */
    async getPrivateLimit() {
        return '2'
    }

    /**
     * @param {*} addr 
     * @returns 
     */
    async mintedNunber(addr) {
        if(!addr) addr = this.provider.account;
        return await this.contract.methods.mintedNunber(addr).call();
    }

    /**
     * @returns 
     */
    async querySellType() {
        return await this.contract.methods.querySellType().call();
    }

    /**
     * @param {*} amount 
     * @param {*} sign 
     * @returns 
     */
    async purchaseWhitelist(amount, sign) {
        let price = await this.getPrivatePrice();
        let value = new BigNumber(price);
        value = amount.multipliedBy(price).shiftedBy(18).toFixed();

        //const ethers = require('ethers');
        //amount = ethers.BigNumber.from(amount.toNumber());
        amount = this.provider.web3.utils.BN(amount.toNumber());
        return await this.provider.executeContract(this.contract, 'purchaseWhitelist', value, [amount, sign]);
    }

    /**
     * @param {*} amount 
     * @returns 
     */
    async purchaseNFT(amount) {
        let price = await this.getPublicPrice();
        let value = new BigNumber(price);
        value = amount.multipliedBy(price).shiftedBy(18).toFixed();

        //const ethers = require('ethers');
        //amount = ethers.BigNumber.from(amount.toNumber());
        amount = this.provider.web3.utils.BN(amount.toNumber());
        return await this.provider.executeContract(this.contract, 'purchaseNFT', value, [amount]);
    }

    /**
     * @param {*} addr 
     * @returns 
     */
    async listMyNFT(addr) {
        if(!addr) addr = this.provider.account;

        return await this.contract.methods.listMyNFT(addr).call();
    }

    /**
     * @returns 
     */
    async totalSupply() {
        return await this.contract.methods.totalSupply().call();
    }

    /**
     * @param {*} addr 
     * @returns 
     */
    async setApprovalForAll(addr) {
        if(!addr) addr = this.provider.account;
        let stakePoolAddr = this.provider.getContractAddr('StakePool');
        return await this.contract.methods.setApprovalForAll(stakePoolAddr, 1).send({from: addr});
    }

    /**
     * @param {*} addr 
     * @returns 
     */
    async isApprovedForAll(addr) {
        if(!addr) addr = this.provider.account;
        let stakePoolAddr = this.provider.getContractAddr('StakePool');
        return await this.contract.methods.isApprovedForAll(addr, stakePoolAddr).call();
    }

    /**
     * @returns 
        inventory 
        privatePrice 
        publicPrice 
        mintLimit 
        privateStart 
        publicStart 
        sellType 
     */
    async parameters() {
        let res = await this.contract.methods.parameters().call();
        let d = {...res};
        d.privatePrice = new BigNumber(res.privatePrice).shiftedBy(-18).toFixed();
        d.publicPrice = new BigNumber(res.publicPrice).shiftedBy(-18).toFixed();
        return d;
    }
}
