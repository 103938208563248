const CHAIN_RPC = {
    1: 'https://mainnet.infura.io/v3/0e47785118b2494092b1a9a9b576c2bd',
    4: 'https://rinkeby.infura.io/v3/0e47785118b2494092b1a9a9b576c2bd',
    42: 'https://kovan.infura.io/v3/0e47785118b2494092b1a9a9b576c2bd',
    56: 'https://bsc-dataseed.binance.org',
    97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
};

const CHAIN_BROWSER = {
    1: 'https://etherscan.io',
    4: 'https://rinkeby.etherscan.io',
    42: 'https://kovan.etherscan.io',
    56: 'https://bscscan.com',
    97: 'https://testnet.bscscan.com',
};

const CHAIN_NAME = {
    1: 'Ethereum Chain Mainnet',
    4: 'Ethereum Chain Rinkeby',
    42: 'Ethereum Chain Kovan',
    56: 'Binance Smart Chain Mainnet',
    97: 'Binance Smart Chain Testnet',
};

const CHAIN_SHORT_NAME = {
    1: 'Etherscan',
    4: 'Rinkeby',
    42: 'Kovan',
    56: 'BscScan',
    97: 'Binance Testnet',
};


const ContractsAddr = {
    1: {
        HaloNFT : "0x9D20Cff2dB7E1C23c3FC6Ef000ea0F36B428E3f5",
        StakePool : "0x3D591226E28c81440eE11e87d731c47dB6dD7F4D",
    },
    4: {
        HaloNFT : "0x9D20Cff2dB7E1C23c3FC6Ef000ea0F36B428E3f5",
    },
    5: {
        HaloNFT : "0xF96aF081146ff283465600eAA3e17f8095303CAF",
        StakePool : "0xC73bFa6F0C3Ed9615cD4Ae624625D0785E37aC42",
    },
    42: {
        HaloNFT : "0x4374705F725cC0A4C96bf8c858DB6Ec5794E9999",
    },
    56: {
        HaloNFT : "0x843d30d95a39D231b8FAbBC98adc363Da9F4d228",
        StakePool : "0xDB79c4Aca0E2cE80Ca67BD92E1995A091A5e177C",
    },
    97: {
        HaloNFT : "0x171458fF05B0615476B6E291A08B17A151B2334C",
        StakePool : "0x9c9e09b0C4cB92C6f990479F7C4DEb4Ce79BeD59",
    },
};

const ApiHost = {
    dev: {
        0 : "https://api.halo-art.xyz/eth/api",
        1 : "https://api.halo-art.xyz/eth_pre/api",
        5 : "https://pandora-test.halonft.art/halo_eth/api",
        56 : "https://api.halo-art.xyz/bsc_pre/api",
        97 : "https://pandora-test.halonft.art/halo_bsc/api",
    },
    pre: {
        0 : "https://api.halo-art.xyz/eth/api",
        1 : "https://api.halo-art.xyz/eth_pre/api",
        5 : "https://pandora-test.halonft.art/halo_eth/api",
        56 : "https://api.halo-art.xyz/bsc_pre/api",
        97 : "https://pandora-test.halonft.art/halo_bsc/api",
    },
    prod: {
        0 : "https://pandora.halonft.art/eth/api",
        1 : "https://pandora.halonft.art/eth/api",
        5 : "https://pandora-test.halonft.art/halo_eth/api",
        56 : "https://pandora.halonft.art/bsc/api",
        97 : "https://pandora-test.halonft.art/halo_bsc/api",
    },
}

const ApiUrl = {
    StakeLogin : "/login",
    StakeClaim : "/user/claimPoint",
    StakeHistory : "/user/history",
    PointInfo : "/store/PointInfo",
    StoreInfo : "/store/getStoreInfo",
    BuyItem : "/user/buySku",
    OrderHistory : "/user/orderHistory",
    preBuy : "/user/preBuy",
}

const ChainSymbol = {
    WToken: {
        1: 'WETH',
        42: 'WETH',
        4: 'WETH',
        56: 'WBNB',
        97: 'WBNB',
    },
    ZeroToken: {
        1: 'ETH',
        4: 'ETH',
        42: 'ETH',
        56: 'BNB',
        97: 'BNB',
    },
};

function getContractsAddr(chainId, name) {
    if (ContractsAddr.hasOwnProperty(chainId) && ContractsAddr[chainId].hasOwnProperty(name)) {
        return ContractsAddr[chainId][name];
    }
    return '';
}

function getApiUrl(chainId, name) {
    let env = 'dev';
    if(process.env && process.env.NODE_ENV) {
        let temp = process.env.NODE_ENV;

        if(temp === 'pre') {
            env = 'pre';
        }
        else if(temp === 'production') {
            env = 'prod';
        }
    }

    if (ApiHost.hasOwnProperty(env) && ApiHost[env].hasOwnProperty(chainId) && ApiUrl.hasOwnProperty(name)) {
        return ApiHost[env][chainId] + ApiUrl[name];
    }
    return '';
}

function getChainShortName(chainId) {
    if (CHAIN_SHORT_NAME.hasOwnProperty(chainId)) {
        return CHAIN_SHORT_NAME[chainId];
    }
    return '';
}

function getChainBrowser(chainId) {
    if (CHAIN_BROWSER.hasOwnProperty(chainId)) {
        return CHAIN_BROWSER[chainId];
    }
    return '';
}

function isZeroAddress(addr) {
    return addr == '0x0000000000000000000000000000000000000000';
}


export { CHAIN_RPC, CHAIN_BROWSER, ContractsAddr, ApiHost, ApiUrl, ChainSymbol, CHAIN_NAME, CHAIN_SHORT_NAME, getContractsAddr, getApiUrl, getChainShortName, getChainBrowser, isZeroAddress };
