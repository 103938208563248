import axios from 'axios';
import StakePoolABI from '../abi/StakePool.json'
import BaseByName from './BaseByName';
export default class StakePool extends BaseByName {
    constructor(provider) {
        console.log('StakePool constructor chainId:', provider.chainId);
        super(provider, StakePoolABI, 'StakePool');
    }

    decodeReturnStruct(method, returnValues) {
        const entryProps = StakePoolABI
            .find((StakePoolABI) => StakePoolABI.name === method)
            .outputs[0].components.map((c) => c.name);

        return returnValues.map((entry) => {
            return Object.fromEntries(
                entry.map((val, idx) => {
                    return [entryProps[idx], val];
                })
            );
        });
    }

    async stakeCondition(stakeType) {
        return await this.contract.methods._stakeCondition(stakeType).call();
    }

    async stakeNFTs(addr, stakeType, tokenIds) {
        if (tokenIds.length === 0) {
            return;
        }
        console.log(stakeType);
        console.log(tokenIds);
        return await this.contract.methods.stakeNFTs(stakeType, tokenIds).send({
            from: addr
        });
    }

    async getCurStakeInfo(stakeType, addr) {
        let stakeInfoList = await this.contract.methods.getCurStakeInfo(stakeType, addr).call();
        // let result = this.decodeReturnStruct("getAllStakeInfo", stakeInfoList);
        let result = this.decodeReturnStruct("getCurStakeInfo", stakeInfoList);
        console.log(result);
        return result;
    }

    async getNewPoint(addr) {
        try {

            let allNewPoint = 0;
            let allUnlockPoint = 0;

            for (let i = 0; i < 3; i++) {
                // let stakeInfoList = await contract.methods.getAllStakeInfo(params.owner).call({
                //     from: contractAddress
                // });

                // let result = utils.decodeReturnStruct(abi, "getAllStakeInfo", stakeInfoList)
                // logger.info(result);

                let stakeInfoList = await this.contract.methods.getStakeInfo(i, addr).call();

                let result = this.decodeReturnStruct("getStakeInfo", stakeInfoList)

                let condition = await this.contract.methods._stakeCondition(i).call({
                    from: addr
                });

                if (result) {

                    for (let k in result) {

                        let time = parseInt(result[k].accumulateTime) + parseInt(result[k].extraTime)
    
                        let stakeTime = parseInt(result[k].stakeTime)
    
                        let unlockTime = 0
    
                        if (i == 2 && stakeTime > 0) {
    
                            let rewardTime = condition[0]*1.2 //30 * 24 * 3600 * 1.2;
                            time += rewardTime
                            unlockTime += rewardTime;
    
                            console.log(">>>>>>", result[k])
                        }
    
                        if (i == 1 && stakeTime > 0) {
    
                            let rewardTime = condition[0]*1.1 //7 * 24 * 3600 * 1.1;
    
                            time += rewardTime
                            unlockTime += rewardTime;
    
                            console.log(">>>>>>", result[k])
                        }
    
                        let p = 0
                        let unlockP = 0;
    
                        if (result[k].tokenId < 100) {
    
                            p = time * 1500 / (24 * 3600)
    
                            unlockP = unlockTime * 1500 / (24 * 3600)
    
                            allNewPoint += p
                            allUnlockPoint += unlockP
    
                        } else {
    
                            p = time * 1000 / (24 * 3600)
                            unlockP = unlockTime * 1000 / (24 * 3600)
    
                            allNewPoint += p
                            allUnlockPoint += unlockP
                        }
                    }
                }
            }

            return {
                code: 10000,
                newPoint: allNewPoint,
                unlockPoint: allUnlockPoint
            };

        } catch (ex) {
            console.log('-----..........', ex)

            return {
                code: 10033,
                result: []
            };
        }
    }

    async getStakeInfo(stakeType, addr) {
        try {
            let stakeInfoList = await this.contract.methods.getStakeInfo(stakeType, addr).call();
            console.log(stakeInfoList);

            return stakeInfoList;

        } catch (ex) {
            console.log('-----..........', ex)

            return [];
        }
    }

    async withdrawNFTs(addr, stakeType, tokenIds) {
        if (tokenIds.length === 0) {
            return;
        }
        return await this.contract.methods.withdrawNFTs(stakeType, tokenIds).send({
            from: addr
        });
    }

    async withdrawAll(addr) {
        return await this.contract.methods.withdrawAll().send({
            from: addr
        });
    }

    async login(addr, srcdata, sigdata) {
        let loginApiUrl = this.provider.getApiUrl('StakeLogin');
        if(!loginApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: loginApiUrl,
            data: {
              account: addr,
              srcdata: srcdata,
              sigdata: sigdata
            }
        });
    }

    async claim(addr, loginToken) {
        let claimApiUrl = this.provider.getApiUrl('StakeClaim');
        if(!claimApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: claimApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                account: addr
            }
        });
    }

    async stakeHistory(addr, loginToken) {
        let historyApiUrl = this.provider.getApiUrl('StakeHistory');
        if(!historyApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: historyApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                account: addr
            }
        });
    }

    async pointInfo(addr, loginToken) {
        let pointInfoApiUrl = this.provider.getApiUrl('PointInfo');
        if(!pointInfoApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: pointInfoApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                account: addr
            }
        });
    }

    async storeInfo() {
        let storeInfoApiUrl = this.provider.getApiUrl('StoreInfo');
        if(!storeInfoApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'get',
            url: storeInfoApiUrl
        });
    }

    async preBuy(skuId, addr, loginToken) {
        let preBuyApiUrl = this.provider.getApiUrl('preBuy');
        if(!preBuyApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: preBuyApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                account: addr,
                skuId: skuId
            }
        });
    }

    async buyItem(skuid, addr, addressInfo, sigdata, loginToken) {
        let buyItemApiUrl = this.provider.getApiUrl('BuyItem');
        if(!buyItemApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: buyItemApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                skuId: skuid,
                account: addr,
                addressInfo: addressInfo,
                recevier: addr,
                sigdata: sigdata,
            }
        });
    }

    async orderHistory(addr, loginToken) {
        let orderHistoryApiUrl = this.provider.getApiUrl('OrderHistory');
        if(!orderHistoryApiUrl) {
            throw ('Get api url error');
        }
        return axios({
            method: 'post',
            url: orderHistoryApiUrl,
            headers: {
                Authorization: loginToken
            },
            data: {
                account: addr,
            }
        });
    }
}

