import { createApp } from 'vue';
import App from './App.vue';
import router from './route';
import { vfmPlugin } from "vue-final-modal";
// import store from '@/store';
import GLOBAL from './utils/global';
import Toast from "vue-toastification";


import "vue-toastification/dist/index.css";
import './assets/css/bootstrap.css';
import './assets/css/reset.css';
import './assets/css/base.css';

Date.prototype.format = function(fmt) {
    let o = {
        "M+" : this.getMonth()+1,                
        "d+" : this.getDate(),                    
        "h+" : this.getHours(),                   
        "m+" : this.getMinutes(),                 
        "s+" : this.getSeconds(),                
        "q+" : Math.floor((this.getMonth()+3)/3), 
        "S"  : this.getMilliseconds()             
    };
    if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(let k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
};


const toastOptions = {
  containerClassName: "custom-toast-container",
  position: "top-center",
  timeout: 3000,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: false,
};

const app = createApp(App)
app.config.globalProperties._global = GLOBAL;
app.use(router)
app.use(vfmPlugin)
app.use(Toast, toastOptions)
// app.use(store)
app.mount('#app')
